import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import PrescriptionCardIcon from './PrescriptionCard.icon';
import './PrescriptionCard.style.scss';

import { AutoRefillPrescriptionCardProps } from './types';
import { addDays, isShipStatusExpired } from 'util/prescription';
import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { RxResult } from 'types/auto-refill';
import { PrescriptionStatuses } from 'components/prescription-statuses';
import { PrescriptionDetails } from 'components/prescription-details';

function showDebuginfo(card: RxResult) {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log(card);
    console.groupEnd();
}

export default function AutoRefillPrescriptionCard({
    fullPayload,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    details = [],
    statuses = [],
    autoRefillBusy,
    autoRefillEnabledForRx,
    autoRefillOnChange,
    autoRefillEligibleForRefill
}: AutoRefillPrescriptionCardProps) {
    const { t } = useTranslation();

    const rxDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'RX';
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);

    const orderDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'ORDER';
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);

    const rxNextRefillStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'NEXT_REFILL';
        });
    }, [statuses]);

    return (
        <Container fluid className={`prescription-card h-100 ${orderStatus.toLowerCase().replace(/ /g, '_')}`}>
            {/* Icon & Auto-refill */}
            <div className="prescription-card__icon_container">
                <div
                    className={'prescription-card__icon-badge-new'}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                        showDebuginfo(fullPayload);
                    }}
                >
                    <PrescriptionCardIcon variant={orderStatus} orderSubStatus={''} t={t} />
                </div>

                <AutoRefillToggle
                    onChange={(val: boolean) => autoRefillOnChange && autoRefillOnChange([fullPayload], val)}
                    isBusy={autoRefillBusy ?? false}
                    t={t}
                    rxEligible={autoRefillEligibleForRefill ?? false}
                    checked={autoRefillEnabledForRx ?? false}
                />
            </div>

            <div className="prescription-card__content-container">
                <div className="prescription-card__content-details">
                    <div className="prescription-card__subtitle">
                        {t('components.prescriptionCard.rxNumberHeaderText', { number: rxNumber })}
                    </div>
                    <div className="prescription-card__title-info-container">
                        <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                            {prescriptionName}
                        </h4>
                    </div>

                    <div className="prescription-card__rx-details-container">
                        <PrescriptionDetails details={details} />
                    </div>

                    <div className="prescription-card__rx-order-statuses">
                        <div className="prescription-card__rx-statuses-container">
                            <div className="prescription-card__rx-statuses">
                                <div className="prescription-card__details-status-headings">
                                    {t('components.prescriptionCard.statusTitle')}
                                </div>
                                <PrescriptionStatuses statuses={rxDisplayStatuses} />
                                {orderDisplayStatuses.length > 0 && (
                                    <>
                                        <div className="prescription-card__details-status-headings font-weight-bold">
                                            {t('components.prescriptionCard.orderStatusText')}
                                        </div>
                                        <PrescriptionStatuses statuses={orderDisplayStatuses} />
                                    </>
                                )}
                            </div>
                            <div className="prescription-card__rx-refills-container">
                                <div className="prescription-card__refills">
                                    <span className="prescription-card__refills-title">
                                        {t('components.prescriptionCard.refillsLeftTitle')}
                                    </span>
                                    <br />
                                    <span className="prescription-card__refills-left">{refillsLeft}</span>
                                </div>
                                <div>
                                    {rxNextRefillStatuses.length > 0 && (
                                        <>
                                            <div className="prescription-card__details-status-headings">
                                                {t('components.prescriptionCard.orderNextRefill')}
                                            </div>
                                            <PrescriptionStatuses statuses={rxNextRefillStatuses} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
