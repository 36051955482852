import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import React from 'react';
import { AutoRefillLearnMoreModalProps } from './types';
import './index.style.scss';

export const AutoRefillLearnMoreModal: React.FC<AutoRefillLearnMoreModalProps> = ({ t }) => {
    return (
        <BirdiModalContent
            title={t('modals.autoRefillLearnMore.title')}
            body={
                <div className="auto-refill-learn-more-body">
                    <span className="text-dark auto-refill-learn-more-label">
                        {t('modals.autoRefillLearnMore.subTitle')}
                    </span>

                    <div className="auto-refill-learn-more-text">
                        <p>{t('modals.autoRefillLearnMore.learnMoreText.paragraphOne')}</p>
                        <p>{t('modals.autoRefillLearnMore.learnMoreText.paragraphTwo')}</p>
                        <p>{t('modals.autoRefillLearnMore.learnMoreText.paragraphThree')}</p>
                    </div>

                    <div className="auto-refill-learn-more-note-container">
                        <span className="text-dark auto-refill-learn-more-label">
                            {t('modals.autoRefillLearnMore.noteTitle')}
                        </span>

                        <div className="auto-refill-learn-more-text">
                            <p>{t('modals.autoRefillLearnMore.noteText.paragraphOne')}</p>
                            <p>{t('modals.autoRefillLearnMore.noteText.paragraphTwo')}</p>
                        </div>
                    </div>
                </div>
            }
            icon={'none'}
        />
    );
};
