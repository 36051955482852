import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import React from 'react';
import { AutoRefillTermsAndConditionsModalProps } from './types';
import './index.style.scss';

export const AutoRefillTermsAndConditionsModal: React.FC<AutoRefillTermsAndConditionsModalProps> = ({ t }) => {
    return (
        <BirdiModalContent
            title={t('modals.autoRefillTC.title')}
            eyebrowText={t('modals.autoRefillTC.eyebrow')}
            body={
                <div className="auto-refill-tc-body">
                    <span className="text-dark auto-refill-tc-terms-label">{t('modals.autoRefillTC.termsLabel')}</span>
                    <ol>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsOne')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsTwo')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsThree')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsFour')}</span>
                        </li>
                    </ol>
                </div>
            }
            icon={'none'}
        />
    );
};
