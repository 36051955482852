import React, { useCallback, useEffect } from 'react';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import { graphql, navigate } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import PageSection from 'ui-kit/page-section/page-section';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import InternalHeader from 'components/internal-header/internal-header.component';
import './index.style.scss';
import { useDispatch, useSelector } from 'react-redux';

import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';
import {
    autoRefillGetPatientDataRoutine,
    autoRefillToggleAutoRefillRxStatusRoutine
} from 'state/auto-refill/auto-refill.reducer';
import {
    autoRefillFirstNameSelector,
    autoRefillLoadingRxsSelector,
    autoRefillRxsLoadedSelector,
    autoRefillAllEnabledSelector,
    autoRefillToggleRxBusySelector,
    autoRefillFlagsSelector,
    autoRefillEligibleRxsSelector,
    autoRefillUserBearerTokenSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillEligibleNotEnabledRxsSelector
} from 'state/auto-refill/auto-refill.selectors';
import Spinner from 'ui-kit/spinner/spinner';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';
import { RxResult } from 'types/auto-refill';
import { easyRefillPrescriptionPayloadToProps } from 'components/prescriptions-list/PrescriptionCard/easyRefillPayloadToProps';
import { Switch } from 'ui-kit/switch';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { EasyRefillErrorModal } from '../../easy-refill';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';
import AutoRefillPrescriptionCard from 'components/prescriptions-list/PrescriptionCard/AutoRefillPrescriptionCard';

const AutoRefillPrescriptions = ({ data }: GetAutoRefillPrescriptionLanguageAndImageDataQuery) => {
    const { t } = useTranslation();

    const { blueSkyBackground } = data;

    const dispatch = useDispatch();

    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.autoRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    // selectors
    const firstName = useSelector(autoRefillFirstNameSelector);
    const autoRefillLoadingRxs = useSelector(autoRefillLoadingRxsSelector);
    const autoRefillBearerToken = useSelector(autoRefillUserBearerTokenSelector);
    const autoRefillEligibleRxs = useSelector(autoRefillEligibleRxsSelector);
    const autoRefillRxsLoaded = useSelector(autoRefillRxsLoadedSelector);
    const autoRefillAllEnabled = useSelector(autoRefillAllEnabledSelector);
    const autoRefillBusy = useSelector(autoRefillToggleRxBusySelector);
    const autoRefillFlags = useSelector(autoRefillFlagsSelector);
    const autoRefillAccountHasInsurance = useSelector(autoRefillAccountHasInsuranceSelector);
    const autoRefillEligibleNotEnabled = useSelector(autoRefillEligibleNotEnabledRxsSelector);

    useEffect(() => {
        // Get Patient Data
        // do not call the patient data if the rxResults already populated
        if (!autoRefillRxsLoaded) {
            dispatch(
                autoRefillGetPatientDataRoutine.trigger({
                    onFailure: (error: string) => {
                        handleShowErrorModal(error);
                    }
                })
            );
        }
    }, [dispatch, autoRefillRxsLoaded, handleShowErrorModal, t]);

    const handleAutoRefillToggle = useCallback(
        (autoRefillRxs: RxResult[], autoRefillEnabled: boolean) => {
            autoRefillRxs.forEach(async (rx) => {
                dispatch(
                    autoRefillToggleAutoRefillRxStatusRoutine.trigger({
                        rxsToToggle: { rxSeqNum: rx.rxSeqNum, newStatus: autoRefillEnabled, rxNumber: rx.rxNumber },
                        onSuccess: () => {
                            dispatch(closeModal({}));
                        }
                    })
                );
            });
        },
        [dispatch]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: RxResult[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'UnauthenticatedAutoRefillTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'UnauthenticatedAutoRefillTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleAutoRefillToggleAllRxs = useCallback(
        (autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(autoRefillEligibleNotEnabled, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(autoRefillEligibleRxs, autoRefillEnabled);
            }
        },
        [autoRefillEligibleNotEnabled, autoRefillEligibleRxs, handleAutoRefillToggle, handleShowTCModal]
    );

    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: RxResult[], autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(rxNumbers, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal]
    );

    const rxAutoRefillEnabled = useCallback(
        (rxNumber: string) => {
            const foundRx = autoRefillFlags.find((rx) => rx.rxNumber === rxNumber);
            if (!foundRx) return false;
            return foundRx.autoRefillEnabled;
        },
        [autoRefillFlags]
    );

    const rxAutoRefillEligible = useCallback(
        (rxNumber: string) => {
            const foundRx = autoRefillFlags.find((rx) => rx.rxNumber === rxNumber);
            if (!foundRx) return false;
            return foundRx.autoRefillEligible;
        },
        [autoRefillFlags]
    );

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    useEffect(() => {
        if (!autoRefillBearerToken) {
            navigate('/link-expired?flow=auto-refill');
        }
    }, [autoRefillBearerToken]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Auto Refill' }}
            suppressApplicationPage={true}
        >
            <PageSection>
                <Container fluid className={`auto-refill-prescriptions`}>
                    <InternalHeader
                        title={t('pages.autoRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.autoRefill.prescriptions.eyebrowText')}
                        sectionIndex={0}
                    />
                    <Row className="mb-5">
                        <Col
                            xs={12}
                            md={12}
                            lg={{ span: 10, offset: 1 }}
                            className="d-flex flex-column justify-content-center align-items-center "
                        >
                            <div className="spacer" />
                            {autoRefillLoadingRxs ? (
                                <Spinner isVisible={autoRefillLoadingRxs} t={t} />
                            ) : (
                                <h3 className="text-center auto-refill-greeting">
                                    {t('pages.autoRefill.prescriptions.greeting', { firstName })}{' '}
                                    <button
                                        onClick={handleAutoRefillModal}
                                        className="auto-refill-greeting-learn-more-link"
                                        role="link"
                                    >
                                        {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                    </button>
                                </h3>
                            )}
                        </Col>
                    </Row>

                    <>
                        {/*
                         * DRX-1598: Added 'false' to avoid showing the auto refill toggle for all cards in the list.
                         * It is temporary change until it be fixed on the back-end.
                         */}
                        {!autoRefillLoadingRxs && autoRefillEligibleRxs.length > 0 && false && (
                            <Row>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={{ span: 10, offset: 1 }}
                                    className="d-flex flex-direction-row mb-4 auto-refill-toggle-all-container"
                                >
                                    <span className="text-dark">{t('components.autoRefillToggle.toggleAllLabel')}</span>
                                    <Switch
                                        checked={autoRefillAllEnabled}
                                        onChange={(val: boolean) => handleAutoRefillToggleAllRxs(val)}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <Row
                                    xs={1}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    className={`d-flex ${autoRefillLoadingRxs ? 'justify-content-center' : ''}`}
                                >
                                    {!autoRefillLoadingRxs && (
                                        <>
                                            {autoRefillEligibleRxs.length > 0 ? (
                                                <>
                                                    {autoRefillEligibleRxs.map((prescription, index) => (
                                                        <Col
                                                            className="mb-4 flex-fill auto-refill-rx-card"
                                                            key={`rx-${index}`}
                                                        >
                                                            <AutoRefillPrescriptionCard
                                                                key={`auto-refill-cabinet-rx-card-${prescription.rxNumber}`}
                                                                {...easyRefillPrescriptionPayloadToProps(
                                                                    prescription,
                                                                    t,
                                                                    autoRefillAccountHasInsurance
                                                                )}
                                                                autoRefillBusy={autoRefillBusy}
                                                                autoRefillEnabledForRx={rxAutoRefillEnabled(
                                                                    prescription.rxNumber
                                                                )}
                                                                autoRefillOnChange={handleShowAutoRefillToggleModal}
                                                                autoRefillEligibleForRefill={rxAutoRefillEligible(
                                                                    prescription.rxNumber
                                                                )}
                                                            />
                                                        </Col>
                                                    ))}
                                                </>
                                            ) : (
                                                <span className="text-dark">
                                                    {t('pages.autoRefill.prescriptions.noResultsFound')}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.easyRefill.prescriptions.sectionHeaders.shippingInformation')}
                                    className="auto-refill-layout-content-section-header"
                                />
                                <Row>
                                    <Col className="auto-refill-prescriptions-section-content p-0">
                                        <span className="auto-refill-prescriptions-section-sentence">
                                            {t('pages.easyRefill.prescriptions.shippingInformation.sentenceOne')}
                                            <a href="/sign-in">
                                                {t('pages.easyRefill.prescriptions.paymentInformation.signIn')}
                                            </a>
                                            {t('pages.easyRefill.prescriptions.shippingInformation.sentenceTwo')}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.easyRefill.prescriptions.sectionHeaders.paymentInformation')}
                                    className="auto-refill-layout-content-section-header"
                                />
                                <Row>
                                    <Col className="auto-refill-prescriptions-section-content p-0">
                                        <span className="auto-refill-prescriptions-section-sentence">
                                            {t('pages.easyRefill.prescriptions.paymentInformation.sentenceOne')}
                                            <a href="/sign-in">
                                                {t('pages.easyRefill.prescriptions.paymentInformation.signIn')}
                                            </a>
                                            {t('pages.easyRefill.prescriptions.paymentInformation.sentenceTwo')}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(AutoRefillPrescriptions, 'auto-refill');

export const query = graphql`
    query GetAutoRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
