import React from 'react';
import { Switch } from 'ui-kit/switch';
import { AutoRefillToggleProps } from './types';
import './index.style.scss';

export const AutoRefillToggle: React.FC<AutoRefillToggleProps> = ({ checked, onChange, t, rxEligible, isBusy }) => {
    return (
        <div className="auto-refill-toggle-container">
            {rxEligible ? (
                <>
                    <div className="auto-refill-toggle-label">
                        <span>{t('components.autoRefillToggle.label')}</span>
                        <span>
                            {checked
                                ? t('components.autoRefillToggle.labelOn')
                                : t('components.autoRefillToggle.labelOff')}
                        </span>
                    </div>
                    <Switch checked={checked} onChange={onChange} disabled={isBusy} />
                </>
            ) : (
                <span className="auto-refill-not-eligible">{t('components.autoRefillToggle.notEligible')}</span>
            )}
        </div>
    );
};
